import { SystemStyleObject } from '@chakra-ui/react';

const baseStyle: SystemStyleObject = {
  p: `2px`,
  borderRadius: 0,
  _hover: {
    bg: `white`,
  },
};

const variants: SystemStyleObject = {
  default: {
    bg: `black`,
    color: `white`,
  },
  transparent: {
    px: 0,
    _hover: {
      bg: `transparent`,
    },
  },
  yellow: {
    _hover: {
      bg: `yellow.75`,
      textColor: `black`,
    },
    fontSize: [10, null, 15],
  },
  white: {
    bg: `white`,
    textColor: `black`,
    py: 5,
    _hover: {
      bg: `yellow.75`,
      textColor: `black`,
    },
    fontSize: [10, null, 15],
  },
  black: {
    bg: `black`,
    textColor: `white`,
    py: 5,
    _hover: {
      bg: `white`,
      textColor: `black`,
    },
    fontSize: [10, null, 15],
  },
};

const defaultProps: SystemStyleObject = {
  variant: `default`,
};

export const Button = {
  baseStyle,
  variants,
  defaultProps,
};
